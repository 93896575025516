.App {
  text-align: center;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #061229;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5575c5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5575c5;
}
